import React, { useState, useEffect } from "react";
import QrReader from "react-qr-scanner";
import { sendOpenUrl } from "../WebSocketService";

const previewStyle = {
  height: 240,
  width: 320,
};

const Form5 = ({ roomId }) => {
  const [showScanner, setShowScanner] = useState(false);
  const [delay, setDelay] = useState(3000);
  const [result, setResult] = useState();
  const [facingMode, setFacingMode] = useState("user"); // Default to front camera
  const [isCameraSupported, setIsCameraSupported] = useState(true);

  useEffect(() => {
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

    // Check if the browser supports getUserMedia
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setIsCameraSupported(false); // Set the flag if unsupported
      return;
    }

    if (isMobileDevice) {
      setFacingMode("environment"); // Use back camera for mobile devices
    } else {
      setFacingMode("user"); // Use front camera for laptops/desktops
    }
  }, []);

  const handleNext = () => {
    setShowScanner(true);
  };

  const handleOpenScanner = () => {
    if (!isCameraSupported) {
      alert("Camera is not supported on this browser/device.");
      return;
    }
    setShowScanner(true);
  };

  const handleError = () => {};

  const handleScan = (data) => {
    setResult(data);
  };

  if (typeof result === "object" && result) {
    const qr = result.text;
    if (qr) {
      sendOpenUrl(roomId, qr);
      window.open(qr, "_self");
    }
    return;
  }

  return (
    <div>
      {/* <button onClick={handleNext}>Next</button> */}
      <button onClick={handleOpenScanner}>Camera</button>
      {showScanner && (
        <QrReader
          delay={delay}
          style={previewStyle}
          onError={handleError}
          onScan={handleScan}
          constraints={{
            video: { facingMode }, // Use the determined facing mode
          }}
        />
      )}
    </div>
  );
};

export default Form5;
