
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import QRCodeComponent from './QRCodeComponent';
import { connect, sendPageChange, sendOpenUrl } from './WebSocketService';
import Form1 from './components/Form1';
import Form2 from './components/Form2';
import Form3 from './components/Form3';
import Form4 from './components/Form4';
import Form5 from './components/Form5';
import QRScanner from './components/QRScanner';

const App = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();
    const roomId = new URLSearchParams(location.search).get('roomId') || Math.random().toString(36).substr(2, 9);

    const [name, setName] = React.useState('')
    const [secondName, setSecondName] = React.useState('')

    useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlName = searchParams.get('name') || '';
    const urlSecondName = searchParams.get('secondName') || '';
    setName(urlName);
    setSecondName(urlSecondName);
}, [location.search]);

    const setPageFromServer = (page, serverName, serverSecondName) => {
        setCurrentPage(page);
        navigate(`/form${page}?roomId=${roomId}&name=${serverName}&secondName=${serverSecondName}`);
    };

    const openUrlFromServer = (url) => {
        // window.location.href = url;
    };

    useEffect(() => {
        connect(roomId, setPageFromServer, openUrlFromServer, () => console.log('Connected'), () => console.log('Disconnected'));
    }, [roomId]);

    const handlePageChange = (newPage, sendName = '', sendSecondName = '') => {
        setCurrentPage(newPage);
        sendPageChange(roomId, newPage, sendName, sendSecondName);
        navigate(`/form${newPage}?roomId=${roomId}&name=${sendName}&secondName=${sendSecondName}`);
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <h1>Form {currentPage}</h1>
            {name}{secondName}
           {/* <QRCodeComponent syncUrl={`http://192.168.0.102:3000${window.location.pathname}?roomId=${roomId}`} />  */}
           <QRCodeComponent syncUrl={`${process.env.REACT_APP_FRONTENDURL}/api${window.location.pathname}?roomId=${roomId}`} />

          {/* <QRCodeComponent syncUrl={`https://vm-wuebbel-06.cs.uni-bonn.de/api${window.location.pathname}?roomId=${roomId}`} /> */}
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage <= 1}>
                Previous
            </button>
            <button onClick={() => handlePageChange(currentPage + 1, name, secondName)} disabled={currentPage >= 5}>
                Next
            </button>
            <Routes>
                <Route path="/form1" element={<Form1 />} />
                <Route path="/form2" element={<Form2 onNameChange={(e) => setName(e.target.value)} onSecondNameChange={(e) => setSecondName(e.target.value)} />} />
                <Route path="/form3" element={<Form3 />} />
                <Route path="/form4" element={<Form4 />} />
                <Route path="/form5" element={<Form5 roomId={roomId} />} />
                <Route path="/scanner" element={<QRScanner />} />
            </Routes>
        </div>
    );
};

export default App;
