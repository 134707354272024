
import { io } from 'socket.io-client';

let socket;
const socketUrl = process.env.REACT_APP_BACKENDURL; // Replace with your local IP address

export const connect = (roomId, onPageChange, onUrlOpen, onConnect, onDisconnect) => {
    socket = io(socketUrl);
    console.log(process.env.REACT_APP_BACKENDURL)
    socket.on('connect', () => {
        console.log(`Connected to WebSocket server: ${socket.id}`);
        socket.emit('join_room', roomId);
        onConnect();
    });

    socket.on('page_changed', ({page, name, secondName}) => {
        console.log(`Page changed to: ${page}`);
        onPageChange(page, name, secondName);
    });

    socket.on('url_opened', (url) => {
        console.log(`URL to open: ${url}`);
        console.log("URLLLLL", url)
        window.location.href = url
    });

    socket.on('disconnect', () => {
        console.log('Disconnected from WebSocket server');
        onDisconnect();
    });
};

export const sendPageChange = (roomId, page, name, secondName) => {
    if (socket) {
        console.log(`Sending page change: Room ${roomId}, Page ${page}`);
        socket.emit('page_change', { roomId, page, name, secondName });
    }
};

export const sendOpenUrl = (roomId, url) => {
    if (socket) {
        console.log(`Sending open URL: Room ${roomId}, URL ${url}`);
        socket.emit('open_url', { roomId, url });
    }
};
