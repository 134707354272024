import React from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPageChange } from '../WebSocketService';

const Form2 = ({onNameChange, onSecondNameChange}) => {
    const navigate = useNavigate();


    const handleNext = () => {
        sendPageChange('form3');
        // navigate(`/form3`);
        // navigate(`/form3?name=${name}&second=${secondName}`);
    };

    return (
        <div>
            <label>
                Name: <input type="text" onChange={(e) => onNameChange(e)} />
            </label>
            <br />
            <label>
                Second Name: <input type="text" onChange={(e) => onSecondNameChange(e)} />
            </label>
            <br />
            {/* <button onClick={handleNext}>Next</button> */}
        </div>
    );
};

export default Form2;
