import React from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPageChange } from '../WebSocketService';

const Form4 = () => {
    const navigate = useNavigate();

    const handleNext = () => {
        sendPageChange('form5');
        navigate('/form5');
    };

    return (
        <div>
            {/* <button onClick={handleNext}>Next</button> */}
        </div>
    );
};

export default Form4;
